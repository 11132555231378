import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import CursorHoverable from "~components/CursorHoverable";

const PortraitMarquee = ({ className, items, keyPrefix }) => {
  const itemListJSX = (
    <ul className="portrait-marquee__list relative flex items-center justify-center mt-v4 mb-v4">
      {items.map((itemJSX, itemIndex) => {
        const key = `${keyPrefix}-item-${itemIndex}`;

        return (
          <li key={key} className="portrait-marquee__list__item relative">
            {itemJSX}
          </li>
        );
      })}
    </ul>
  );

  return (
    <section
      key={`${keyPrefix}-container`}
      className={`portrait-marquee ${className ||
        `w-full relative`} xs:pt-v8 overflow-x-hidden`}
    >
      <CursorHoverable>
        <Link
          to="/prints"
          className="portrait-marquee__link w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center"
        >
          <h2 className="f1">Shop Now</h2>
        </Link>
      </CursorHoverable>

      <div className="w-screen relative flex items-center justify-center">
        {itemListJSX}
        {itemListJSX}
      </div>
    </section>
  );
};

PortraitMarquee.defaultProps = {
  className: ``,
  keyPrefix: `glide-carousel`
};

PortraitMarquee.propTypes = {
  className: PropTypes.string,
  items: PropTypes.node.isRequired,
  keyPrefix: PropTypes.string
};

export default PortraitMarquee;
