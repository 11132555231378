/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext";
import Carousel from "~components/Carousel";
import CursorHoverable from "~components/CursorHoverable";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import Newsletter from "~components/Newsletter";
import Parallaxer from "~components/Parallaxer";
import PortraitMarquee from "~components/PortraitMarquee";
import PreviousExhibitions from "~components/PreviousExhibitions";
import ScreenHeight from "~components/ScreenHeight";
import SEO from "~components/SEO";
import LiquidDistorter from "~components/LiquidDistorter";
import { getMediaJSX, splitStringToParagraphs } from "~utils/helpers";

const IndexPage = ({ data, location }) => {
  const { device } = useContext(DocumentContext);

  //

  const { allMarkdownRemark, markdownRemark } = data;
  const { frontmatter } = markdownRemark;

  let bannerExhibition;

  if (allMarkdownRemark?.edges?.[0]) {
    allMarkdownRemark.edges.forEach(({ node }) => {
      if (
        node.frontmatter.title.toLowerCase() ===
        frontmatter.lastExhibition.toLowerCase()
      ) {
        bannerExhibition = node;
      }
    });
  }

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="index-page w-full relative overflow-x-hidden bg-black text-white">
        {frontmatter.exhibitionStatus === `None` && (
          <>
            <section className="index-page__banner w-full relative flex items-center justify-center bg-onyx">
              {bannerExhibition?.frontmatter?.carousel?.[0] && (
                <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0">
                  {device && device === `desktop` && (
                    <>
                      {(bannerExhibition?.frontmatter?.carousel?.[1] && (
                        <Carousel
                          items={bannerExhibition.frontmatter.carousel.map(
                            (item, itemIndex) => {
                              const key = `item-${itemIndex}`;

                              return (
                                <figure
                                  key={key}
                                  className="w-full relative block"
                                >
                                  <Img
                                    fluid={item.image.childImageSharp.fluid}
                                    alt=""
                                  />
                                </figure>
                              );
                            }
                          )}
                        />
                      )) || (
                        <figure className="w-full relative block">
                          <Img
                            fluid={
                              bannerExhibition.frontmatter.carousel[0].image
                                .childImageSharp.fluid
                            }
                            alt=""
                          />
                        </figure>
                      )}
                    </>
                  )}

                  {device && device !== `desktop` && (
                    <>
                      {(bannerExhibition?.frontmatter?.carouselXS?.[1] && (
                        <Carousel
                          items={bannerExhibition.frontmatter.carouselXS.map(
                            (item, itemIndex) => {
                              const key = `item-${itemIndex}`;

                              return (
                                <figure
                                  key={key}
                                  className="w-full h-full relative block"
                                >
                                  <Img
                                    className="w-full h-full object-cover"
                                    fluid={item.image.childImageSharp.fluid}
                                    alt=""
                                  />
                                </figure>
                              );
                            }
                          )}
                        />
                      )) || (
                        <figure className="w-full h-full relative block">
                          <Img
                            className="w-full h-full object-cover"
                            fluid={
                              bannerExhibition.frontmatter.carouselXS[0].image
                                .childImageSharp.fluid
                            }
                            alt=""
                          />
                        </figure>
                      )}
                    </>
                  )}
                </div>
              )}

              <div className="w-full absolute right-0 bottom-0 left-0 z-10 flex justify-between xs:justify-center pt-v1 pr-v3 pl-v3 pb-v2 xs:pb-8">
                <h2 className="b1">
                  {bannerExhibition.frontmatter.bannerHeading}
                </h2>

                {device && device === `desktop` && (
                  <h2 className="b1">
                    <CursorHoverable>
                      <Link to={bannerExhibition.fields.slug}>
                        View gallery
                      </Link>
                    </CursorHoverable>
                  </h2>
                )}
              </div>
            </section>

            <section className="w-full relative z-10 pt-v7 xs:pt-v20 bg-black">
              <div className="grid">
                <article className="grid-end-22 xs:grid-end-24 grid-start-2 xs:grid-start-1">
                  <h4 className="mb-v1 xs:mb-v6 b2">
                    {frontmatter.infoHeading}
                  </h4>

                  {splitStringToParagraphs(
                    frontmatter.info,
                    `mb-v2 xs:mb-v6 whitespace-pre-wrap ${
                      device && device === `desktop` ? `f3` : `f5`
                    }`
                  )}
                </article>
              </div>
            </section>

            <Newsletter />

            <div id="exhibitions">
              <PreviousExhibitions />
            </div>
          </>
        )}

        {frontmatter.exhibitionStatus === `Active` && (
          <>
            <div className="animation-appear-down-slow animation-delay-5 w-full desktop-only sixteen-by-nine relative">
              <div className="w-full absolute bottom-0 right-0 left-0 z-10 flex items-center justify-center mb-v3 b1">
                <CursorHoverable>
                  <Link to="/prints" className="hover-underline">
                    Shop Prints
                  </Link>
                </CursorHoverable>
              </div>

              <Parallaxer
                atTop
                className="w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center"
                reverse
                severe
              >
                {device && device === `desktop` && (
                  <LiquidDistorter
                    displacementMap="/webgl/liquid-displacement.jpg"
                    image={
                      frontmatter.bannerBackground.childImageSharp.fluid.src
                    }
                  />
                )}
              </Parallaxer>
            </div>

            {device && device !== `desktop` && (
              <ScreenHeight className="relative overflow-hidden touch-only">
                {frontmatter?.bannerBackgroundXS?.childImageSharp?.fluid && (
                  <Img
                    className="w-full h-full absolute transform-center object-cover"
                    fluid={frontmatter.bannerBackgroundXS.childImageSharp.fluid}
                    alt="Scroll Distorter Banner"
                  />
                )}
              </ScreenHeight>
            )}

            <section className="w-full relative z-10 pt-v7 xs:pt-v20 bg-black">
              <div className="grid">
                <article className="grid-end-22 xs:grid-end-24 grid-start-2 xs:grid-start-1">
                  <h4 className="mb-v1 xs:mb-v6 b2">
                    {frontmatter.infoHeading}
                  </h4>

                  {splitStringToParagraphs(
                    frontmatter.info,
                    `mb-v2 xs:mb-v6 whitespace-pre-wrap ${
                      device && device === `desktop` ? `f3` : `f5`
                    }`
                  )}
                </article>
              </div>
            </section>

            {frontmatter?.carousel?.[0] && (
              <PortraitMarquee
                keyPrefix="home-carousel"
                items={frontmatter.carousel.map(carouselItem => {
                  return getMediaJSX(carouselItem);
                })}
              />
            )}

            <section className="w-full relative pt-v6 xs:pt-v12 pb-v6">
              <div className="grid">
                <article className="grid-end-22 xs:grid-end-24 grid-start-2 xs:grid-start-1">
                  <h4 className="b2">{frontmatter.artistsHeading}</h4>

                  <p
                    className={`mt-v1 xs:mt-v6 ${
                      device && device === `desktop` ? `f3` : `f5`
                    }`}
                  >
                    {frontmatter.artists}
                  </p>

                  <CursorHoverable>
                    <Link
                      to="/artists"
                      className={`block mt-v3 xs:mt-v8 hover-underline ${
                        device && device === `desktop` ? `f5` : `b1`
                      }`}
                    >
                      + See all artists
                    </Link>
                  </CursorHoverable>
                </article>
              </div>
            </section>

            <Newsletter />

            <section className="w-full relative block pt-v8 xs:pt-v16 pb-v10">
              <div className="grid">
                <div className="grid-end-22 xs:grid-end-24 grid-start-2 xs:grid-start-1">
                  <h3 className="mb-v1 b1">{frontmatter.aboutHeading}</h3>

                  {splitStringToParagraphs(
                    frontmatter.about,
                    `xs:mt-v6 mb-v2 xs:mb-v6 whitespace-pre-wrap ${
                      device && device === `desktop` ? `f3` : `f5`
                    }`
                  )}

                  <h3
                    className={`xs:mt-v12 ${
                      device && device === `desktop` ? `f5` : `b1`
                    }`}
                  >
                    <CursorHoverable>
                      <a
                        href="https://www.with-you.studio"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="block mt-v4 hover-underline"
                      >
                        + Visit With You
                      </a>
                    </CursorHoverable>
                  </h3>
                </div>
              </div>
            </section>
          </>
        )}
      </Layout>

      <Footer />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        exhibitionStatus
        lastExhibition
        bannerBackground {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          publicURL
        }
        bannerBackgroundXS {
          childImageSharp {
            fluid(maxWidth: 768, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          publicURL
        }
        bannerBackgroundAlt
        infoHeading
        info
        carousel {
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          imageAlt
          videoURL
          videoURLXS
          videoControls
        }
        artistsHeading
        artists
        aboutHeading
        about
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "exhibition-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            bannerHeading
            carousel {
              image {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
                publicURL
              }
            }
            carouselXS {
              image {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          productType
          vendor
          variants {
            id
            title
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
            }
          }
        }
      }
    }
  }
`;
