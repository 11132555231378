import React, { useContext } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";

const query = graphql`
  query PreviousExhibitions {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "exhibition-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
          }
        }
      }
    }
  }
`;

const PreviousExhibitions = () => {
  const { device } = useContext(DocumentContext);

  //

  return (
    <StaticQuery
      query={query}
      render={data => {
        const exhibitions = [];

        if (data?.allMarkdownRemark?.edges?.[0]?.node) {
          data.allMarkdownRemark.edges.forEach(({ node }) => {
            exhibitions.push(node);
          });
        }

        //

        return (
          <section className="w-full relative block xs:mt-24 mb-v8 xs:mb-4">
            <div className="grid">
              <article className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1">
                <h3 className="b2">Past exhibitions</h3>
              </article>

              {exhibitions?.[0] && (
                <ul className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1">
                  {exhibitions.map((exhibition, exhibitionIndex) => {
                    return (
                      <li
                        key={exhibition.fields.slug}
                        className={`relative ${
                          exhibitionIndex > 0 ? `mt-v3` : `mt-v1`
                        } xs:mb-16`}
                      >
                        <h2 className="xs:mt-8 xs:mb-2 f3">
                          {exhibition.frontmatter.title}
                        </h2>

                        <div className="w-full relative flex xs:block items-center justify-between mt-v1">
                          <h3
                            className={`${
                              device === `desktop` ? `f5` : `b1`
                            } italic`}
                          >
                            {exhibition.frontmatter.date}
                          </h3>

                          <h3
                            className={`${
                              device === `desktop` ? `f5` : `mt-6 b1`
                            }`}
                          >
                            <CursorHoverable>
                              <Link
                                to={exhibition.fields.slug}
                                className="hover-underline"
                              >
                                View gallery
                              </Link>
                            </CursorHoverable>
                          </h3>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </section>
        );
      }}
    />
  );
};

export default PreviousExhibitions;
