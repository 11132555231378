/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef, useState } from "react";
import anime from "animejs";
import isNode from "detect-node";
import { CursorContext } from "~context/CursorContext";
import { DocumentContext } from "~context/DocumentContext";
import { ieDetector } from "~utils/screen";

const LiquidDistorter = ({ displacementMap, image }) => {
  const { cursorCenterDeltaX, cursorCenterDeltaY } = useContext(CursorContext);
  const { device, windowHeight, windowWidth } = useContext(DocumentContext);

  //----------------------------------------------------------------------------
  //
  // Microsoft / SSR bailout

  const ie = ieDetector();

  if (
    typeof window === `undefined` ||
    (device && device !== `desktop`) ||
    (ie && ie > 10)
  ) {
    return (
      <div className="w-full h-full absolute">
        <img
          className="w-full h-full xs:h-auto absolute transform-center object-cover"
          src={image}
          alt="Scroll Distorter Banner"
        />
      </div>
    );
  }

  const containerRef = useRef();
  const [canvas, setCanvas] = useState(null);
  const [displacer, setDisplacer] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [pixiApp, setPixiApp] = useState(null);
  const [pixiSprite, setPixiSprite] = useState(null);
  const [PIXI, setPIXI] = useState(null);
  const [started, setStarted] = useState(false);

  //----------------------------------------------------------------------------
  //
  // functions

  const start = (loader, resources) => {
    if (isNode || typeof window === `undefined`) {
      return;
    }

    const newPixiApp = new PIXI.Application({
      width: canvas.width,
      height: canvas.height,
      transparent: false
    });

    containerRef.current.appendChild(newPixiApp.view);

    //

    const container = new PIXI.Container();
    const background = new PIXI.Sprite(resources.backgroundImage.texture);

    background.width = canvas.width;
    background.height = canvas.height;
    // F1EBE5

    const displacementSprite = new PIXI.Sprite(
      resources.displacementMap.texture
    );
    displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;

    const displacementFilter = new PIXI.filters.DisplacementFilter(
      displacementSprite
    );

    container.filters = [displacementFilter];

    container.addChild(background);
    background.addChild(displacementSprite);

    displacementSprite.scale.x = 3;
    displacementSprite.scale.y = 3;

    newPixiApp.renderer.backgroundColor = 0xf1ebe5;
    newPixiApp.stage.addChild(container);

    setDisplacer(displacementSprite);
    setPixiApp(newPixiApp);
    setPixiSprite(background);
  };

  //----------------------------------------------------------------------------
  //
  // hook - DOM ref

  useEffect(() => {
    if (containerRef.current && !loaded && window) {
      setLoaded(true);

      let pixi;

      if (typeof window !== `undefined`) {
        // eslint-disable-next-line global-require
        pixi = require(`pixi.js`);
        pixi.utils.skipHello();
      }

      setPIXI(pixi);
    }
  }, [containerRef.current]);

  //----------------------------------------------------------------------------
  //
  // hook - PIXI

  useEffect(() => {
    if (!PIXI) {
      return;
    }

    const canvasBoundingRect = containerRef.current.getBoundingClientRect();
    const { width, height } = canvasBoundingRect;

    setCanvas({
      width,
      height,
      displacementMap,
      image
    });
  }, [PIXI]);

  //----------------------------------------------------------------------------
  //
  // hook - canvas

  useEffect(() => {
    if (!canvas) {
      return;
    }

    if (!started) {
      setStarted(true);

      const pixiLoader = new PIXI.Loader();

      pixiLoader
        .add(`displacementMap`, canvas.displacementMap)
        .add(`backgroundImage`, canvas.image)
        .load(start);
    } else if (pixiApp) {
      pixiApp.renderer.resize(canvas.width, canvas.height);
      pixiSprite.width = canvas.width;
      pixiSprite.height = canvas.height;
    }
  }, [canvas]);

  //----------------------------------------------------------------------------
  //
  // hook - window resize

  useEffect(() => {
    if (!containerRef || !containerRef.current || !canvas) {
      return;
    }

    const containerBoundingRect = containerRef.current.getBoundingClientRect();
    const { width, height } = containerBoundingRect;

    setCanvas({
      ...canvas,
      width,
      height
    });
  }, [windowHeight, windowWidth]);

  //----------------------------------------------------------------------------
  //
  // hook - animation

  useEffect(() => {
    if (!pixiApp) {
      return;
    }

    anime({
      targets: displacer,
      x: parseInt(cursorCenterDeltaX * 1000),
      y: parseInt(cursorCenterDeltaY * 1000),
      duration: 2000,
      easing: `easeOutExpo`
    });
  }, [cursorCenterDeltaX, cursorCenterDeltaY]);

  //----------------------------------------------------------------------------
  //
  // DOM output

  return (
    <div
      ref={containerRef}
      role="presentation"
      className="liquid-distorter w-full h-full relative bg-cream"
    ></div>
  );
};

export default LiquidDistorter;
