import React, { useContext, useEffect, useRef, useState } from "react";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";
import { fancyError, fancyLog, validateEmail } from "~utils/helpers";

const Newsletter = () => {
  const { device } = useContext(DocumentContext);

  const formRef = useRef();

  const [email, setEmail] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);

  //

  const onSubmit = e => {
    e.preventDefault();

    if (
      typeof window !== `undefined` &&
      window.location.href.includes(`localhost:8000`)
    ) {
      setSubmitting(true);

      setTimeout(() => {
        setSubmitting(false);
        setSubmitted(true);
      }, 3000);

      return false;
    }

    if (
      !valid ||
      submitting ||
      submitted ||
      !process.env.GATSBY_NETLIFY_FUNCTIONS ||
      !process.env.GATSBY_MAILCHIMP_API_KEY ||
      !process.env.GATSBY_MAILCHIMP_LIST_ID
    ) {
      return false;
    }

    setSubmitting(true);

    const mailchimpData = {
      email,
      list_id: process.env.GATSBY_MAILCHIMP_LIST_ID
    };

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/mailchimp`, {
      body: JSON.stringify(mailchimpData),
      method: `POST`
    })
      .then(() => {
        fancyLog(`Mailchimp Complete`);
        setSubmitting(false);
        setSubmitted(true);
      })
      .catch(error => {
        fancyError(error);
      });

    return false;
  };

  //

  useEffect(() => {
    setValid(validateEmail(email));
  }, [email]);

  //

  return (
    <section className="newsletter w-full relative pt-v6 xs:pt-v20 pb-v12 overflow-hidden bg-black text-white">
      <form ref={formRef} className="grid" onSubmit={onSubmit}>
        <div className="grid-end-11 xs:grid-end-24 grid-start-2 xs:grid-start-1">
          <h3 className="b1">Stay updated</h3>

          <input
            className={`w-full relative block mt-v2 xs:mt-v6 pb-v1 border-b-white ${
              device && device === `desktop` ? `f3` : `f5`
            } ${
              submitting || submitted ? `opacity-50 pointer-events-none` : ``
            }`}
            onChange={e => setEmail(e.currentTarget.value)}
            placeholder="Enter your email"
            readOnly={submitting || submitted}
            type="email"
          />

          <div className="flex justify-end">
            <CursorHoverable>
              <input
                className={`mt-v1 block b1 ${
                  valid
                    ? `cursor-pointer hover-underline text-white`
                    : `pointer-events-none text-grey`
                } ${
                  submitting || submitted
                    ? `opacity-50 pointer-events-none`
                    : ``
                }`}
                readOnly={submitting || submitted}
                type="submit"
                value={submitted ? `Thanks!` : `Submit`}
              />
            </CursorHoverable>
          </div>

          <h3 className="desktop-only mt-v3 f5">
            <CursorHoverable>
              <a
                href="https://www.instagram.com/with_you.exhibition/"
                rel="noopener noreferrer"
                target="_blank"
                className="hover-underline"
              >
                + Instagram
              </a>
            </CursorHoverable>
          </h3>
        </div>

        <div className="grid-end-7 xs:grid-end-17 grid-start-15 xs:grid-start-1">
          <p
            className={`mt-v3 xs:mt-v6 ${
              device && device === `desktop` ? `f5` : `b1`
            }`}
          >
            By subscribing, you agree to receive occasional updates on upcoming
            exhibitions.
          </p>
        </div>
      </form>
    </section>
  );
};

export default Newsletter;
